import React, { useState } from "react";
import "./card.css";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";

interface ProgProps {
  data: any;
}

const ProgramCards: React.FC<ProgProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleNav = (id: string) => {
    navigate(`/careers/${id}`);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="w-full max-w-full bg-white rounded-xl shadow-lg mb-2 carCard"
      // data-aos="fade-in"
    >
      {/* Accordion Header */}
      <div
        className="flex justify-between items-center p-3 cursor-pointer h-full"
        onClick={toggleAccordion}
      >
        <div className="flex flex-col justify-between w-full h-full">
          <div className="flex items-start justify-between w-full ">
            <div className="md:text-xl capitalize text-left text-base font-bold pr-3">
              {data?.name}
            </div>
            <div className="font-medium  text-primary text-[14px] min-w-max">
              <span className="text-gray-300">Last Date: </span>
              {data?.last_date &&
                moment(data?.last_date).format("DD-MM-YY, hh:mm")}
            </div>

            {/* <div className="relative flex items-center justify-end ">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleNav(data?.id);
                }}
                className="relative flex h-6 items-center justify-center px-3 text-sm font-semibold text-white bg-primary rounded-full hover:scale-105 transition-all"
              >
                Apply
              </button>
            </div> */}
          </div>

          <div className=" venturi text-start text-[14px] mt-3">
            {data?.short_desc ? data?.short_desc : "No data to display"}
            {/* Our mission is to empower organizations through exceptional
            procurement consultancy services. We focus on optimizing supply
            chains, reducing costs, and enabling our clients to achieve their
            strategic objectives. By delivering innovative sourcing solutions
            tailored to individual needs, we position ourselves as trusted
            partners in our clients' pursuit of operational excellence and
            sustainable growth. */}
          </div>

          <div className="flex justify-between items-end mt-3">
            {/* <div className="flex items-center mt-1">
              <div className="mb-1 text-primary text-[14px]">Tags&nbsp;: </div>
              <div className="flex py-3 mx-3 h-6 w-fit items-center justify-center px-2 text-gray-700 text-[10px] border border-gray-700 rounded-3xl shadow-lg capitalize">
                career
              </div>
            </div> */}
            {data?.eligibility && (
              <div className="flex items-start ">
                <div className=" text-primary text-[14px]">
                  Eligibility&nbsp;:{" "}
                </div>
                <div className=" venturi text-start text-[14px] ml-2">
                  {data?.eligibility
                    ? data?.eligibility
                    : "No eligibility to display"}
                  {/* Our mission is to empower organizations through exceptional
                  procurement consultancy services. We focus on optimizing
                  supply chains, reducing costs, */}
                </div>
              </div>
            )}
          </div>
          <div className="mt-3  text-lg w-full flex justify-end ">
            {/* {isOpen ? <FaChevronDown /> : <FaChevronRight />} */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleNav(data?.id);
              }}
              className=" flex h-6 items-center justify-center px-3 text-sm font-semibold text-white bg-primary rounded-full hover:scale-105 transition-all"
            >
              Read&nbsp;More
            </button>
          </div>
        </div>
      </div>

      {/* Accordion Content */}
      {/* {isOpen && (
        <div className="p-3 my-3 ">
          <div className=" venturi text-start text-[13px] ml-3">
            {data?.description
              ? parse(data?.description)
              : "No data to display"}
          </div>
          <div className="ml-3  text-lg pt-3 flex justify-center">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleNav(data?.id);
              }}
              className="relative flex h-6 items-center justify-center px-3 text-sm font-semibold text-white bg-primary rounded-full hover:scale-105 transition-all"
            >
              Apply
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ProgramCards;
