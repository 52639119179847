import React, { useEffect, useState } from "react";
import Cards from "./components/Cards";
import Container from "../../../components/Container";
import Spacer from "../../../components/Spacer";
import { useNavigate, useParams } from "react-router-dom";
import DetailData from "./components/detail";
import SpacerMini from "../../../components/SpacerMini";
import axiosInstance from "../../../../utils/axios";
import { FaChevronLeft } from "react-icons/fa";
import ProductImageGalleryLeftThumb from "../updateDetail/components/newDetail";
import { useAppContext } from "../../../../appContext";

const ProgramDetail: React.FC = () => {
  const param = useParams().id;
  const {setLoading} = useAppContext()
  const [data, setData] = useState<any>({});
  const [show, setShow] = useState(true);
  const fetchProgrameData = async (id: any) => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/events/${id}`
      );
      setData({
        ...response.data,
        gallery: [
          { id: 999, image: response?.data?.image },
          ...response.data.gallery,
        ],
      });
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchProgrameData(param);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="relative" id="home">
      <div
        aria-hidden="true"
        className="absolute  grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
      >
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
      </div>{" "}
      <div className="flex items-center justify-center flex-col">
        <div className="py-32 w-full">
          <Container>
            {/* <About /> */}
            <div
              className="flex items-center text-primary cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <FaChevronLeft />
              <span className="pl-2 pb-[1px]">Back</span>{" "}
            </div>
            <SpacerMini />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:h-[calc(100vh-300px)]">
              <div className="col-span-1 md:order-2 order-2 md:overflow-y-auto h-full scrollbar-thin scrollbar-thumb-gray-300 ">
                <div className="space-y-4 ">
                  <div className="md:block hidden ">
                    <Cards data={data} />
                  </div>
                  <DetailData data={data} />
                  <div className="">
                    {data?.gform_link && !data?.expired && (
                      <div className="z-50">
                        <iframe
                          src={data?.gform_link}
                          title="program registration"
                          style={{ width: "100%", height: "calc(100vh)" }}
                          className="block"
                        ></iframe>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-1 md:order-1 order-1 h-full scrollbar-thin scrollbar-thumb-gray-300">
                <div className="">
                  <div className="block md:hidden">
                    <Cards data={data} />
                  </div>
                  {data?.gallery && (
                    <ProductImageGalleryLeftThumb data={data?.gallery} thumb />
                  )}
                </div>
              </div>
            </div>
            <Spacer />
          </Container>
        </div>
      </div>
    </div>
  );
};
export default ProgramDetail;
