import React from 'react'
import Container from "../../../components/Container";
import { Link } from 'react-router-dom';
const TermsAndConditions: React.FC = () => {
    return (
        <div className='py-5'>
            <div className="relative" id="terms">
                <div
                    aria-hidden="true"
                    className="absolute inset-0 grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
                >
                    <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
                    <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
                </div>
              

            </div>
            <div className='py-20'>
            <Container>
                    <div className='md:py-40 py-10'>
                    <h1 className="font-bold text-3xl text-gray-900 dark:text-white  ">Terms of Use for Strategic Sourcing Middle East (SSME) Website</h1>
                        <div className="mt-8 text-gray-700 dark:text-gray-300 text-lg">
                            <p><strong>Effective Date:</strong> 11/11/2024</p>

                            <p>Welcome to the Strategic Sourcing Middle East website, located at <a href="https://www.stratsourceme.com" target='_blank' rel="noreferrer" className='text-primary font-bold  cursor-pointer'>www.stratsourceme.com</a> (the “Site”). By accessing or using our Site, you agree to comply with and be bound by these Terms of Use (“Terms”). If you do not agree to these Terms, please do not use our Site.</p>

                            <br /><h2 className="font-bold text-2xl">1. Acceptance of Terms</h2>
                            <p>By using the Site, you acknowledge that you have read, understood, and agree to be bound by these Terms. These Terms may be updated periodically, and your continued use of the Site constitutes acceptance of any changes.</p>

                            <br /><h2 className="font-bold text-2xl">2. Description of Services</h2>
                            <p>Strategic Sourcing Middle East provides procurement consultancy services, resources, and related content through this Site. Any new features or tools added to the current Site will also be subject to these Terms.</p>

                            <br /><h2 className="font-bold text-2xl">3. Use of Site Content</h2>
                            <ul>
                                <li>All content on the Site, including text, graphics, logos, images, and other material (collectively, “Content”), is the property of Strategic Sourcing Middle East and is protected by intellectual property laws. You may not reproduce, distribute, modify, create derivative works of, or publicly display any Content without our prior written permission.</li>
                                <li>Content on this Site is for informational purposes only and is not intended as professional advice. Please consult a professional for specific advice applicable to your circumstances.</li>
                            </ul>

                            <br /><h2 className="font-bold text-2xl">4. Prohibited Uses</h2>
                            <ul>
                                <li>You agree not to use the Site for any unlawful purpose, to solicit others to perform or participate in unlawful acts, or to violate any international, federal, provincial, or state regulations, rules, laws, or local ordinances.</li>
                                <li>You agree not to interfere with or disrupt the Site’s security or attempt to gain unauthorized access to the Site, Content, or the Site’s servers.</li>
                            </ul>

                            <br /><h2 className="font-bold text-2xl">5. User Submissions</h2>
                            <ul>
                                <li>Any material you submit to the Site, including comments, feedback, or other information (“Submissions”), becomes the property of Strategic Sourcing Middle East. By submitting content, you grant Strategic Sourcing Middle East a perpetual, royalty-free, irrevocable, non-exclusive license to use, reproduce, modify, publish, translate, and distribute your Submission.</li>
                                <li>Strategic Sourcing Middle East reserves the right, but is not obligated, to monitor, edit, or remove any Submission at its discretion.</li>
                            </ul>

                            <br /><h2 className="font-bold text-2xl">6. Third-Party Links</h2>
                            <p>Our Site may contain links to third-party websites. Strategic Sourcing Middle East is not responsible for the content, accuracy, or opinions expressed on these websites. Inclusion of any linked website does not imply approval or endorsement by Strategic Sourcing Middle East.</p>

                            <br /><h2 className="font-bold text-2xl">7. Limitation of Liability</h2>
                            <p>To the fullest extent permitted by law, Strategic Sourcing Middle East will not be liable for any damages of any kind arising from your use of, or inability to use, the Site, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>

                            <br /><h2 className="font-bold text-2xl">8. Indemnification</h2>
                            <p>You agree to indemnify, defend, and hold harmless Strategic Sourcing Middle East, its officers, directors, employees, and agents from any claim, liability, damage, loss, or demand arising out of your use of the Site, violation of these Terms, or violation of any rights of a third party.</p>

                            <br /><h2 className="font-bold text-2xl">9. Privacy Policy</h2>
                            <p>Your use of the Site is also governed by our <Link to="/privacy-policy" className='text-primary font-bold  cursor-pointer'>Privacy Policy</Link>. Please review this policy to understand our practices regarding the handling of your personal information.</p>

                            <br /><h2 className="font-bold text-2xl">10. Governing Law</h2>
                            <p>These Terms and your use of the Site will be governed by and construed in accordance with the laws of the United Arab Emirates, without regard to conflict of law principles.</p>

                            <br /><h2 className="font-bold text-2xl">11. Termination</h2>
                            <p>Strategic Sourcing Middle East reserves the right, in its sole discretion, to terminate your access to all or part of the Site, with or without notice, for any reason.</p>

                            <br /><h2 className="font-bold text-2xl">12. Changes to Terms</h2>
                            <p>Strategic Sourcing Middle East reserves the right to change these Terms at any time. Any updates to the Terms will be posted on this page with an updated effective date. Your continued use of the Site after such changes constitutes your acceptance of the revised Terms.</p>

                            <br /><h2 className="font-bold text-2xl">13. Contact Information</h2>
                            <p>For questions regarding these Terms of Use, please contact us at <a href="mailto:hello@stratsourceme" className='text-primary font-bold  cursor-pointer'>hello@stratsourceme.com</a>.</p>
                        </div>
                    </div>
                </Container>
                </div>
        </div>
    )
}

export default TermsAndConditions