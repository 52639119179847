import React, { ReactNode, useContext, useState, createContext } from "react";

// Define the shape of the context
export type AppContextType = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

// Create the context with a default value of `undefined`
const AppContext = createContext<AppContextType | undefined>(undefined);

// AppProvider component to wrap the application and provide context
export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to consume the AppContext
export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
