import React from "react";

const About: React.FC = () => {
  return (
    <div
      className=" text-justify
       flex "
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
    >
      <div className="container">
        <div className="welcome-content text-center ">
          <h5 className="font-medium text-xl text-gray-600 dark:text-gray-300">
            Who Are We
          </h5>
          <h1 className="font-medium text-3xl text-gray-900 dark:text-white p-3 ">
            Welcome to Strategic Sourcing Middle East
          </h1>
          <div className="w-full pt-3 flex  justify-center">
            <div className="border-b-2  border-b-black w-[100px] dark:border-b-white "></div>
          </div>
          <p
            data-aos="zoom-in"
            data-aos-duration="1000"
            className="p-10 pb-1  text-gray-600 dark:text-gray-300 font-normal text-base md:text-justify text-center"
          >
            Strategic Sourcing Middle East is a premier procurement consultancy
            and recruitment services firm headquartered in the UAE with a global
            footprint. Our expertise spans procurement management, vendor
            relations, and workforce solutions, making us a trusted partner for
            businesses worldwide. With a vetted network of over 10,000 vendors
            and access to exceptional talent pools, we seamlessly integrate
            strategic sourcing and recruitment to meet the diverse needs of our
            clients.
          </p>
          <br />
          <p
            data-aos="zoom-in"
            data-aos-duration="1000"
            className="p-10 pt-1  text-gray-600 dark:text-gray-300 font-normal text-base md:text-justify text-center"
          >
            Backed by a team of seasoned professionals, we pride ourselves on
            delivering innovative, tailored solutions that enhance efficiency,
            drive growth, and ensure long-term success. At Strategic Sourcing
            Middle East, we are committed to being your go-to partner for
            procurement and recruitment excellence in today’s dynamic business
            environment.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
