import React, { useEffect, useRef, useState } from "react";
import HeroSection from "./components/HeroSection";
import Cards from "../about/components/Cards";
import Container from "../../../components/Container";
import SpacerMini from "../../../components/SpacerMini";
import About from "../programs/components/About";
import UpdateAbout from "../updates/components/About";
import ProgramCards from "../programs/components/Cards";
import Spacer from "../../../components/Spacer";
import UpdateCardsSol from "../updates/components/Cards";
import UpdateCards from "./components/updateCards/Cards";
import { Link } from "react-router-dom";
import MultiCarousal from "../../../components/multicarousals/multi.carousal";
import axiosInstance from "../../../../utils/axios";
import SimpleSlider from "../../../components/slider/slider";
import Popup2 from "../../../components/popup2/popup2";

import Up from "../../../../assets/svg/up.svg";
import Dwn from "../../../../assets/svg/dwn.svg";
import { useAppContext } from "../../../../appContext";

// import Features from "./components/Features";
// import Stats from "./components/Stats";
// import Testimonials from "./components/Testimonials";
// import CallToAction from "./components/CallToAction";
// import Blog from "./components/Blog";

const Home: React.FC = () => {
  const [programData, setProgramData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [open, setOpen] = useState(false);
  const [clickedData, setClickedData] = useState("");
  const [popupData, setPopupData] = useState({});
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeImage, setActiveImage] = useState(0);
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(true);
  const [load2, setLoad2] = useState(true);
  const { setLoading } = useAppContext();
  const fetchUpdateData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/insights/list`
      );
      setUpdateData(response.data);
      setLoad(false);
    } catch (err: any) {
      setLoad(false);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchUpdateData();
  }, []);

  const fetchProgramData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/events/list`
      );
      // console.log(response)
      setProgramData(response?.data?.results);
      setLoad2(false);
    } catch (err: any) {
      setLoad2(false);
    } finally {
      setLoad2(false);
    }
  };
  // const fetchProgramDetails = async (id: any) => {
  //   try {
  //     const response = await axiosInstance.get(
  //       `https://api.stratsourceme.com/api/v1/insights/${id}`
  //     );
  //     setPopupData(response.data);
  //   } catch (err: any) {}
  // };
  // useEffect(() => {
  //   fetchProgramDetails(clickedData);
  // }, [clickedData]);
  useEffect(() => {
    fetchProgramData();
  }, []);

  useEffect(() => {
    // console.log(load, load2)
    if (!load && !load2) {
      setLoading(false);
    }
  }, [load, load2]);
  // console.log(load, load2);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (updateData && updateData.length > 0) {
  //       setCount((prevCount) => {
  //         const newCount = prevCount + 1;
  //         setActiveImage(newCount % updateData.length); // Cycle through indices
  //         return newCount;
  //       });
  //     }
  //   }, 15000);

  //   return () => clearInterval(interval);
  // }, [updateData]);
  const handleScroll = (direction: "left" | "right") => {
    console.log(direction, count);
    if (direction === "left" && activeImage !== 0) {
      setActiveImage(activeImage - 1);
    } else if (direction === "right" && activeImage < updateData?.length - 1) {
      setActiveImage(activeImage + 1);
    }
    if (containerRef.current) {
      const scrollAmount = 160; // Scroll distance (slightly more than 150px)
      const scrollPosition = containerRef.current.scrollLeft;
      const newScrollPosition =
        direction === "left"
          ? scrollPosition - scrollAmount
          : scrollPosition + scrollAmount;

      // Smooth scroll to the new position
      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };
  console.log(activeImage, count);
  return (
    <>
      <div className="flex items-center justify-center flex-col w-full">
        <div className="flex min-h-screen h-full items-center w-full">
          <HeroSection />
        </div>
        {/* <div></div> */}
        <Spacer />
        <Container>
          <Cards />
          <Spacer />
          {programData.length !== 0 && (
            <>
              <About />
              <Spacer />
              <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center h-full w-full">
                {programData?.map(
                  (item, i) => i <= 2 && <ProgramCards key={i} data={item} />
                )}
              </div>
              {/* <div className="grid  grid-cols-1  text-center ">
          <MultiCarousal>
            {[1, 2, 3,4,5].map((item, i) => (
              <ProgramCards key={i} />
            ))}
          </MultiCarousal>s
        </div> */}
              <Spacer />
              <div className="flex justify-center">
                <Link
                  to={"/programs"}
                  // onClick={() => handleNav()}
                  className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-xl before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                >
                  <span className="relative text-sm font-semibold text-white">
                    View More
                  </span>
                </Link>
              </div>{" "}
            </>
          )}
          {updateData.length !== 0 && (
            <>
              <Spacer />
              <UpdateAbout />
              <Spacer />
              {/* <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center ">
          {[1, 2, 3].map((item, i) => (
            <UpdateCardsSol key={i} />
          ))}
        </div> */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 w-full">
                <div className="max-w-full ">
                  {updateData?.map(
                    (item, i) =>
                      activeImage === i && (
                        <div className="">
                          <UpdateCards
                            key={i}
                            data={item}
                            only={true}
                            active={true}
                            open={open}
                            setOpen={setOpen}
                            // setClickedData={setClickedData}
                          />
                        </div>
                      )
                  )}
                </div>
                <div className=" flex flex-col items-center justify-between h-full">
                  {/* Top Button */}
                  {/* {activeImage !== 0 && ( */}
                  <button
                    className={`w-32 z-10 transform  rounded-full mb-2 p-2 boxSh  flex justify-center border border-primary ${
                      activeImage === 0
                        ? "bg-gray-300"
                        : "bg-white hover:bg-gray-50 cursor-pointer"
                    }`}
                    style={{ left: "50%" }} // Center horizontally
                    // disabled={activeImage == 0}
                    onClick={() => {
                      activeImage !== 0
                        ? handleScroll("left")
                        : console.log("no further contents");
                    }}
                  >
                    <img src={Dwn} className="w-5 rotate-180 text-white" />
                  </button>
                  {/* )} */}

                  {/* Cards */}
                  {/* <div> */}
                  <div className="w-full md:relative  flex items-center justify-center h-full ">
                    {updateData?.map((item, i) => {
                      const isPrev = activeImage === i + 1;
                      // ||
                      // (i === 0 && activeImage === updateData.length - 1);
                      const isNext = activeImage === i - 1;
                      // ||
                      // (i === updateData.length - 1 && activeImage === 0);
                      const isCurrent = activeImage === i;

                      return (
                        <div
                          key={i}
                          className={`md:absolute transition-all duration-300 ease-in-out w-full  ${
                            isCurrent
                              ? "opacity-100 scale-75 z-10"
                              : isPrev || isNext
                              ? "opacity-75 z-0 hidden md:block"
                              : "hidden"
                          }`}
                          style={{
                            transform: isPrev
                              ? "translateY(-30%)"
                              : isNext
                              ? "translateY(30%)"
                              : "translateY(0)",
                          }}
                        >
                          <UpdateCards
                            data={item}
                            act={true}
                            active={isCurrent}
                            open={open}
                            setOpen={setOpen}
                            // setClickedData={setClickedData}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {/* </div> */}

                  {/* Bottom Button */}
                  {/* {activeImage !== updateData?.length - 1 && ( */}
                  <button
                    className={`w-32 z-10 transform  rounded-full mt-2  p-2 boxSh  flex justify-center border border-primary ${
                      activeImage == updateData?.length - 1
                        ? "bg-gray-300"
                        : "bg-white hover:bg-gray-50 cursor-pointer"
                    }`}
                    style={{ left: "50%" }} // Center horizontally
                    // disabled={activeImage == updateData?.length - 1}
                    onClick={() => {
                      activeImage !== updateData?.length - 1
                        ? handleScroll("right")
                        : console.log("no further contents");
                    }}
                  >
                    <img src={Dwn} className="w-5 text-white" />
                  </button>
                  {/* )} */}
                </div>
              </div>

              {/* <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center "> */}

              {/* </div> */}
              <Spacer />
              <div className="flex justify-center">
                <Link
                  to={"/updates"}
                  // onClick={() => handleNav()}
                  className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-xl before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                >
                  <span className="relative text-sm font-semibold text-white">
                    View More
                  </span>
                </Link>
              </div>
            </>
          )}
          <SpacerMini />

          {/* <Features />
      <Stats />
      <Testimonials />
      <CallToAction />
      <Blog/> */}
        </Container>
        {/* <About /> */}
      </div>
      {/* {open && popupData && <Popup2 setOpen={setOpen} data={popupData} />} */}
    </>
  );
};

export default Home;
