import React, { useEffect } from "react";
import { useSeo } from "../seo/SeoContext";
import { SEO_DEFAULTS } from "../seo/constants";
import { routeSEO } from "../routes/paths";
import Footer from "./components/Footer";
import Header from "./components/Header";
interface PublicRouteProps {
  children: React.ReactNode;
  slug: string;
}

export const Public: React.FC<PublicRouteProps> = ({ children, slug }) => {
  const { updateSeoData } = useSeo();

  useEffect(() => {
    updateSeoData(
      routeSEO[slug]?.title || SEO_DEFAULTS.TITLE,
      routeSEO[slug]?.description || SEO_DEFAULTS.DESC,
      routeSEO[slug]?.keywords || SEO_DEFAULTS.KEYWORDS
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  // Render children if logged in, otherwise redirect to login page
  return (
    <>
      <Header />
      <div
        className="pb-10"
        style={{ minHeight: "calc(100dvh - 385px)" }}
      >
        {children}
      </div>
      <Footer />
    </>
  );
};
