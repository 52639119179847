import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import emailjs from "@emailjs/browser";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";

type ContactFormInputs = {
  name: string;
  email: string;
  mobile: string;
  isWhatsApp: boolean;
  whatsappNumber?: string;
  message: string;
  countryCode: string;
  whatsappCountryCode?: string | "";
};

const schema = z
  .object({
    name: z.string().min(2, "Name is required").max(50),
    email: z.string().email("Invalid email address"),

    mobile: z
      .string()
      .regex(/^\+\d{7,15}$/, "Invalid phone number (7-15 digits)"),
    isWhatsApp: z.boolean(),

    whatsappNumber: z
      .string()
      .regex(/^\+\d{7,15}$/, "Invalid WhatsApp number (7-15 digits)")
      .optional()
      .or(z.literal("")),
    message: z
      .string()
      .min(10, "Message required and should be at least 10 characters long."),
  })
  .refine((data) => data.isWhatsApp || data.whatsappNumber, {
    message:
      "Please provide a WhatsApp number if the above is not a WhatsApp number",
    path: ["whatsappNumber"],
  });

const ContactForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showWhatsAppField, setShowWhatsAppField] = useState(true);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ContactFormInputs>({
    resolver: zodResolver(schema),
  });

  const sendEmail = async (data: ContactFormInputs) => {
    try {
      const templateParams = {
        name: data.name || "No name provided",
        email: data.email || "email",
        mobile: `${data.mobile}` || "mobile",
        whatsappNumber: !data?.isWhatsApp
          ? `${data.whatsappNumber}`
          : `${data.mobile}`,
        message: data.message || "message",
      };

      await emailjs.send(
        process.env.REACT_APP_EMAIL_SERVICE_ID!,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID!,
        templateParams,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY!
      );
      enqueueSnackbar("Enquiry submitted successfully", {
        variant: "success",
      });
      navigate("/");
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  };

  const onSubmit: SubmitHandler<ContactFormInputs> = async (data) => {
    await sendEmail(data);
    setShowWhatsAppField(true);
    reset();
  };
  useEffect(() => {
    const vart = document.getElementById("cont-main");
  }, []);
  return (
    <div className="max-w-lg mx-auto text-gray-900 p-8 z-1 relative">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Name Field */}
        <div className="mb-4 ">
          {/* <label className="block font-semibold">Name</label> */}
          <input
            type="text"
            {...register("name")}
            placeholder="Name"
            className="w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder-black"
          />
          {errors.name && (
            <p className="text-red-500 text-sm">{errors.name.message}</p>
          )}
        </div>

        {/* Email Field */}
        <div className="mb-4">
          {/* <label className="block Contact font-semibold">Email</label> */}
          <input
            type="email"
            {...register("email")}
            placeholder="Email"
            className="w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder-black"
          />
          {errors.email && (
            <p className="text-red-500 text-sm">{errors.email.message}</p>
          )}
        </div>

        {/* Mobile Number Field */}
        {/* <div className="mb-4">
          <input
            type="tel"
            {...register("mobile")}
            placeholder="Mobile Number"
            className="w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder-black"
          />
          {errors.mobile && (
            <p className="text-red-500 text-sm">{errors.mobile.message}</p>
          )}
        </div> */}

        {/* WhatsApp Checkbox */}
        {/* <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            {...register("isWhatsApp")}
            onChange={(e) => setShowWhatsAppField(!e.target.checked)}
            className="mr-2 w-5 h-5 "
          />
          <label className="Contact dark:text-white">
            Is the above number a WhatsApp number?
          </label>
        </div> */}

        {/* Conditional WhatsApp Number Field */}
        {/* {showWhatsAppField && (
          <div className="mb-4">
            <input
              type="tel"
              {...register("whatsappNumber")}
              placeholder="Whatsapp Number"
              className="w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder-black"
            />
            {errors.whatsappNumber && (
              <p className="text-red-500 text-sm">
                {errors.whatsappNumber.message}
              </p>
            )}
          </div>
        )} */}

        <div className="mb-4 flex">
          {/* Country Code Field for Mobile Number */}
          {/* <input
            type="text"
            {...register("countryCode")}
            placeholder="1"
            maxLength={4}
            className="w-24 p-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-primary"
          /> */}

          <Controller
            name="mobile"
            control={control}
            render={({ field }) => (
              <PhoneInput
                international
                id="cont-main"
                defaultCountry="IN"
                className="w-full bg-white contact-country p-2 mt-2 focus:border-none border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder-black"
                value={watch()?.mobile}
                style={{ outline: "none" }}
                onChange={(v: any) => setValue("mobile", v)}
              />
            )}
          />

          {/* <input
            type="tel"
            {...register("mobile")}
            placeholder="Phone Number"
            className="w-full p-2 border rounded-r-md focus:outline-none focus:ring-2 focus:ring-primary"
          /> */}
        </div>
        {errors.mobile && (
          <p className="text-red-500 text-sm">{errors.mobile.message}</p>
        )}
        {errors.countryCode && (
          <p className="text-red-500 text-sm">{errors.countryCode.message}</p>
        )}

        {/* WhatsApp Checkbox */}
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            {...register("isWhatsApp")}
            onChange={(e) => setShowWhatsAppField(!e.target.checked)}
            className="mr-2 w-5 h-5 "
          />
          <label className="Contact dark:text-white">
            Is the above number a WhatsApp number?
          </label>
        </div>

        {/* Conditional WhatsApp Number Field */}
        {showWhatsAppField && (
          <div className="mb-4 flex ">
            {/* Country Code Field for WhatsApp Number */}
            {/* <input
              type="text"
              {...register("whatsappCountryCode")}
              placeholder="+1"
              // maxLength={4}
              className="w-24 p-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <input
              type="tel"
              {...register("whatsappNumber")}
              placeholder="WhatsApp Number"
              className="w-full p-2 border rounded-r-md focus:outline-none focus:ring-2 focus:ring-primary"
            /> */}
            <Controller
              name="whatsappNumber"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  international
                  id="cont-main"
                  defaultCountry="IN"
                  className="w-full bg-white contact-country p-2 mt-2 focus:border-none border rounded-md focus:outline-none focus:ring-2 focus:ring-primary  placeholder-black"
                  value={watch()?.whatsappNumber}
                  style={{ outline: "none" }}
                  onChange={(v: any) => setValue("whatsappNumber", v)}
                />
              )}
            />
          </div>
        )}
        {errors.whatsappNumber && showWhatsAppField && (
          <p className="text-red-500 text-sm">
            {errors.whatsappNumber.message}
          </p>
        )}
        {errors.whatsappCountryCode && showWhatsAppField && (
          <p className="text-red-500 text-sm">
            {errors.whatsappCountryCode.message}
          </p>
        )}

        {/* Message Field */}
        <div className="mb-6">
          {/* <label className="block Contact font-semibold">Message</label> */}
          <textarea
            {...register("message")}
            placeholder="Type your message"
            rows={5}
            className="w-full p-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder-black"
          />
          {errors.message && (
            <p className="text-red-500 text-sm">{errors.message.message}</p>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded-md transition duration-300"
        >
          {isSubmitting ? "Sending..." : "Send Message"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
