import React, { useEffect, useState } from "react";
import { setUser } from "../utils/auth";

import Loader from "../app/components/Loaders";
import { useAppContext } from "../appContext";

// Define type for children prop (assuming React.FC)
interface MainWrapperProps {
  children: React.ReactNode;
}

export const MainWrapper: React.FC<MainWrapperProps> = ({ children }) => {
  // const [loading, setLoading] = useState(true);
  const { loading } = useAppContext();

  // useEffect(() => {
  //   const handler = async () => {
  //     setLoading(true);
  //     await setUser();
  //     // Assuming this is an async function that sets the user data

  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 2000);
  //   };

  //   handler();
  // }, []);

  return (
    <>
      {loading && (
        <div
          className="loadMain fixed flex items-center justify-center z-50 h-[100dvh] w-[100dvw]"
          style={{}}
        >
          <Loader />
        </div>
      )}
      <div style={{ height: "100dvh" }}>{children}</div>
    </>
  );
};
