import React, { useState } from "react";
import SearchIcon from "../../../../../assets/svg/search.svg";
import FilterIcon from "../../../../../assets/svg/filter.svg";

interface FilterProps {
  handleSearch: (value?: string, stats?: string) => void;
  stats?: string;
  setStats: React.Dispatch<React.SetStateAction<string>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const Filter: React.FC<FilterProps> = ({
  handleSearch,
  stats,
  setStats,
  setSearch,
}) => {
  const [query, setQuery] = useState("");
  const [stat, setStat] = useState(stats);
  const [open, setOpen] = useState(false);
  console.log(query);
  return (
    <div
      className={`relative z-50 group p-2 sm:p-2 rounded-xl bg-white border border-gray-100 dark:border-gray-700 dark:bg-gray-800 bg-opacity-50  shadow-gray-600/10 ${
        query !== "" ? "shadow-glow" : "hover:shadow-glow "
      } `}
    >
      <div className="flex flex-col-reverse sm:flex-row justify-between ">
        <div className="relative  sm:mt-0 mt-2">
          <select
            className="appearance-none text-primary rounded-md px-4 py-1 pr-10 md:w-72  dark:border-none border border-1 dark:focus:ring-0 block h-9 border-gray-300 dark:border-gray-700 dark:bg-gray-700 bg-gray-50 dark:text-white focus:outline-none focus:ring-primary w-full"
            value={stats}
            onChange={(e) => {
              setStats(e?.target?.value);
            }}
          >
            <option value="live">Live</option>
            {/* <option value="ongoing">Ongoing</option> */}
            <option value="past">Past</option>
          </select>
          <span className="absolute inset-y-0 right-2 flex items-center pointer-events-none text-gray-800 dark:text-white">
            ▼
          </span>
        </div>
        <div className="flex justify-end">
          <input
            type="text"
            placeholder="Search..."
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setSearch(e.target.value);
            }}
            className="rounded-md px-4 py-1 dark:border-none border border-1 focus:outline-none dark:focus:ring-0 "
            style={{
              fontSize: "16px",
              width: "330px",
              // border: "0",
            }}
          />
          <button
            onClick={() => {
              handleSearch(query);
            }}
            className="bg-primary text-white px-2  rounded-md ml-2 cursor-pointer block"
          >
            <img src={SearchIcon} className="w-5 text-white" />
          </button>
          {/* <button
            onClick={() => {
              setOpen(!open);
              // Replace with your filter handler function
            }}
            className="bg-primary text-white px-2 rounded-md ml-2 cursor-pointer block"
          >
            <img src={FilterIcon} className="w-5 h-4 text-white" alt="Filter" />
          </button> */}
        </div>
      </div>
      {open && (
        <div className="absolute right-0 top-14 bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-700 rounded-xl shadow-lg p-4 z-50 w-72">
          <h3 className="text-lg font-bold dark:text-white">Filter Options</h3>
          {/* Example Filters */}
          <div className="mt-4">
            {/* <div className="text-primary">Clear Filter</div> */}
            {/* <label className="block text-sm dark:text-gray-300">
                Mode:
                <select
                  className="mt-1 block w-full h-9 rounded-md px-1 border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-white focus:outline-none focus:ring-primary"
                  onChange={(e) => {
                    setMode(e?.target?.value);
                  }}
                >
                  <option value="all">All</option>
                  <option value="offline">Offline</option>
                  <option value="online">Online</option>
                </select>
              </label> */}
            <label className="block text-sm dark:text-gray-300 mt-2">
              Status:
              <select
                className="mt-1 block w-full h-9 rounded-md px-1 border-gray-300 dark:border-gray-700 dark:bg-gray-700 dark:text-white focus:outline-none focus:ring-primary"
                value={stats ?? "live"}
                onChange={(e) => {
                  setStats(e?.target?.value);
                }}
              >
                {/* <option value="">All</option> */}
                <option value="live">Live</option>
                {/* <option value="ongoing">Ongoing</option> */}
                <option value="past">Past</option>
              </select>
            </label>
            <button
              onClick={() => {
                handleSearch(query ?? "", stat ?? "all");
                setOpen(false);
              }}
              className="mt-4 bg-primary text-white px-4 py-2 rounded-md block w-full"
            >
              Apply Filters
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
