import React from "react";
import "./card.css";
// import Back from "../../../../../assets/hero.jpeg";
// import Eye from "../../../../../assets/svg/eye";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";

interface ProgProps {
  data: any;
  featured?: boolean;
}

const ProgramCards: React.FC<ProgProps> = ({ data, featured }) => {
  const navigate = useNavigate();
  const handleNav = (id: string) => {
    navigate(`/programs/${id}`);
  };
  return (
    <>
      <div
        className=" flex flex-col  items-start w-full max-w-full bg-gray-50 rounded-3xl border border-gray-300 dark:border-gray-700  hover:scale-105 transition-transform duration-300 cursor-pointer progCarDetail boxSh2"
        onClick={() => handleNav(data?.id)}
      >
        <div
          className="relative bg-white border-b border-b-gray-100 dark:shadow-none dark:border-b-gray-700 dark:bg-gray-800 shadow-gray-600/10 rounded-t-3xl w-full h-[200px]"
          // data-aos="zoom-in-up"
        >
          {/* Ribbon with Star */}
          {/* <div
            className="absolute -top-2 -right-7 transform rotate-45  bg-primary font-bold px-8 py-2 text-lg shadow-lg overflow-hidden rounded-t-full"
            style={{
              maxWidth: "100%",
              clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
            }}
          >
            ★
          </div> */}
          {/* <div className="ribbon ribbon-top-right"><span>★</span></div> */}
          {featured && <div className="ribbon text-white">★</div>}
          <img
            src={data?.image}
            className="program-card-image2 rounded-t-3xl"
            alt="Program"
          />
        </div>

        <div className="  p-3 w-full">
          <div className="  text-left mx-1">
            <div className="text-primary font-bold text-[14px]">
              <div className="flex justify-between">
                <span className="">
                  <span className="pr-2 text-gray-800">Start Date : </span>

                  {data?.start_datetime &&
                    moment(data?.start_datetime).format("DD-MM-YY")}
                </span>{" "}
                {/* <span>----</span> */}
                <span className="">
                  <span className="pr-2 text-gray-800">End Date : </span>
                  {data?.end_datetime &&
                    moment(data?.end_datetime).format("DD-MM-YY")}
                </span>
              </div>
            </div>
            <div className=" text-gray-800 rounded-t-3xl  text-[22px] text-left font-semibold my-2">
              <div className=" capitalize ">{data?.name ?? "event name"}</div>
            </div>
            {/* <p className="my-2 text-gray-700  w-full max-w-md h-24 overflow-hidden text-ellipsis whitespace-normal leading-6"> */}
            <p className="my-1 text-gray-700 venturi w-full max-w-md  min-h-[82px] text-[16px]">
              {data?.short_desc
                ? parse(data?.short_desc)
                : "No data to display"}
              {/* Our mission is to empower organizations through exceptional
              procurement consultancy services. We focus on optimizing supply
              chains, reducing costs, and enabling our clients to achieve their
              strategic objectives. By delivering innovative sourcing solutions
              tailored to individual needs, we position ourselves as trusted
              partners in our clients' pursuit of operational excellence and
              sustainable growth. */}
            </p>
            {/* <div className="text-primary text-[14px] my-2">
              Venue : <span className="font-bold ">{data?.venue}</span>
            </div> */}
            {/* <p className="text-primary readMore">...Read More</p> */}
          </div>
        </div>
        {/* <div className="absolute bottom-4 right-4">
          <div
            onClick={() => handleNav(data?.id)}
            className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
          >
            <span className="relative text-sm font-semibold text-white">
              Read More
            </span>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProgramCards;
