import React, { useState, useEffect, useRef } from "react";
import Up from "../../../../../assets/svg/up.svg";
import Popup2 from "../../../../components/popup2/popup2";

interface ImageData {
  image: string;
  id: string;
}

interface GalleryProps {
  data: ImageData[];
  thumb?: boolean;
  active?: number;
}
const ProductImageGalleryLeftThumb: React.FC<GalleryProps> = ({
  data,
  active,
  thumb,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeImage, setActiveImage] = useState(active ?? 0);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);

  // useEffect(() => {
  //   if (thumb) {
  //     const interval = setInterval(() => {
  //       setCount(count + 1);
  //       setActiveImage(count % data?.length);
  //     }, 10000);

  //     return () => clearInterval(interval);
  //   }
  // }, [count]);
  const handleClick = (i: number) => {
    setActiveImage(i);
    setCount(i);
  };

  /////////////////////////////////////

  const handleScroll = (direction: "left" | "right") => {
    console.log(direction, count);
    if (direction === "left" && activeImage !== 0) {
      setActiveImage(activeImage - 1);
    } else if (direction === "right" && activeImage < data?.length - 1) {
      setActiveImage(activeImage + 1);
    }
    if (containerRef.current) {
      const scrollAmount = 160;
      const scrollPosition = containerRef.current.scrollLeft;
      const newScrollPosition =
        direction === "left"
          ? scrollPosition - scrollAmount
          : scrollPosition + scrollAmount;
      containerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <section className="h-full">
        <div
          className={`relative w-full flex flex-col ${
            !thumb ? "h-full justify-center items-center" : ""
          }`}
        >
          <div
            className={` flex justify-center items-center ${
              thumb
                ? "h-[calc(100vh-430px)] sm:bg-[#eeeeeec9] dark:sm:bg-[#192644]"
                : "sm:h-[calc(100vh-230px)] h-[calc(100vh)"
            }`}
          >
            {activeImage > 0 && (
              <button
                className="absolute left-0  transform -translate-y-1/2 bg-primary rounded-full p-2 shadow-md py-4 "
                onClick={() => {
                  handleScroll("left");
                }}
              >
                <img src={Up} className="w-5 text-white -rotate-90" />
              </button>
            )}
            <img
              src={data?.[activeImage]?.image}
              className={`w-auto h-full object-contain ${
                thumb ? "cursor-pointer" : ""
              }`}
              onClick={() => thumb && setShow(true)}
            />
            {activeImage < data?.length - 1 && (
              <button
                className="absolute right-0 transform -translate-y-1/2 bg-primary rounded-full p-2 py-4 shadow-md "
                onClick={() => handleScroll("right")}
              >
                <img src={Up} className="w-5 text-white rotate-90" />
              </button>
            )}
          </div>
          {thumb && (
            <div className="py-2">
              <div
                className={`overflow-x-auto ${data[activeImage]?.id}   flex gap-[10px]`}
                ref={containerRef}
              >
                {data?.map((item: any, i: number) => (
                  <div
                    className="min-w-[150px] max-w-[150px] h-[150px]  bg-white"
                    onClick={() => {
                      handleClick(i);
                    }}
                  >
                    <img
                      src={item?.image}
                      className={`in-w-[150px] max-w-[150px] h-[150px] object-cover`}
                      width={150}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
      {show && <Popup2 setOpen={setShow} active={activeImage} data={data} />}
    </>
  );
};

export default ProductImageGalleryLeftThumb;
