import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { object, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Select from "react-select";
import axiosInstance from "../../../../../utils/axios";
import AsyncSelect from "react-select/async";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import moment from "moment";
import SpacerMini from "../../../../components/SpacerMini";
import PhoneInput from "react-phone-number-input";
import Loader from "../../../../components/Loaders";
import { useAppContext } from "../../../../../appContext";

interface Country {
  id: string;
  name: string;
}
const today = new Date();
const minAllowedDate = new Date(
  today.getFullYear() - 18,
  today.getMonth(),
  today.getDate()
);

const schema = z.object({
  fullName: z
    .string({ required_error: "Name is required" })
    .min(1, "Full Name is required"),
  dob: z
    .string({ required_error: "DOB is required" })
    .min(1, "Date of Birth is required")
    .refine(
      (dateStr) => {
        const selectedDate = new Date(dateStr);
        return selectedDate <= minAllowedDate;
      },
      {
        message: "You must be at least 18 years old",
      }
    ),
  nationality: z.object({
    value: z
      .string({ required_error: "Nationality is required" })
      .min(1, "Nationality is required"),
    label: z
      .string({ required_error: "Nationality is required" })
      .min(1, "Nationality is required"),
  }),
  // currentLocation: z.object({
  //   value: z.string().min(1, "Current Location is required"),
  //   label: z.string().min(1, "Current Location is required"),
  // }),
  currentLocation: z
    .string({ required_error: "Location is required" })
    .min(1, "Current Location is required"),
  visaStatus: z.object({
    value: z
      .string({ required_error: "Visa Status is required" })
      .min(1, "Visa Status is required"),
    label: z
      .string({ required_error: "Visa Status is required" })
      .min(1, "Visa Status is required"),
  }),
  // qualOther: z.boolean().optional(),
  otherQualification: z
    .string({ required_error: "Qualification is required" })
    .min(1, "Qualification is required"),
  exprtOther: z.boolean().optional(),
  otherExpertise: z.string().optional(),
  exprtArea: z.boolean().optional(),
  otherArea: z.string().optional(),
  // drivingLicense: z.object({
  hasLicense: z.object({
    value: z.string({ required_error: "Required" }).min(1, "Required"),
    label: z.string({ required_error: "Required" }).min(1, "Required"),
  }),
  country: z.object({
    label: z
      .string({ required_error: "License issuing country is required" })
      .min(1, "License issuing country is required"),
    value: z
      .string({ required_error: "License issuing country is required" })
      .min(1, "License issuing country is required"),
  }),

  type: z.object({
    label: z
      .string({ required_error: "License type is required" })
      .min(1, "License type is required"),
    value: z
      .string({ required_error: "License type is required" })
      .min(1, "License type is required"),
  }),

  // }),
  mobileNumber: z
    .string({ required_error: "Phone Number is required" })
    .regex(/^\+\d{7,15}$/, "Invalid phone number (7-15 digits)"),
  whatsapp_number: z
    .string({ required_error: "Whatsapp Number is required" })
    .regex(/^\+\d{7,15}$/, "Invalid phone number (7-15 digits)")
    .transform((v) => (v == undefined ? "" : v)),
  email: z
    .string({ required_error: "Email Address is required" })
    .email("Invalid email address"),
  // linkedin: z.string().url("Invalid URL"),
  linkedin: z
    .string({ required_error: "Linkedin profile url is required" })
    .regex(
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/,
      "Invalid LinkedIn profile URL"
    )
    .optional(),
  highestQualification: z.object({
    label: z
      .string({ required_error: "Qualification is required" })
      .min(1, "Qualification is required"),
    value: z
      .string({ required_error: "Qualification is required" })
      .min(1, "Qualification is required"),
  }),
  fieldOfSpecialization: z
    .string({ required_error: "Specialization is required" })
    .min(1, "Specialization is required"),
  experience: z.object({
    label: z
      .string({ required_error: "Experience is required" })
      .min(1, "Experience is required"),
    value: z
      .string({ required_error: "Experience is required" })
      .min(1, "Experience is required"),
  }),
  certifications: z.string().optional(),
  currentPosition: z.string().optional(),
  currentOrganization: z.string().optional(),
  // industryExpertise: z
  //   .array(
  //     z.object({
  //       label: z.string(),
  //       value: z.string(),
  //     })
  //   )
  //   .optional(),
  // areasOfExpertise: z
  //   .array(
  //     z.object({
  //       label: z.string(),
  //       value: z.string(),
  //     })
  //   )
  //   .optional(),
  resume: z.any().refine((file) => file && file[0], "Resume is required"),
});
// .refine((data) => {
//   // if (!data?.qualOther || data?.otherQualification?.trim()?.length) {
//   //   return {
//   //     message: "This field is required .",
//   //     path: ["otherQualification"],
//   //   };
//   // }
//   // if (data?.exprtOther || data?.otherExpertise?.trim()?.length) {
//   //   return {
//   //     message: "This field is required .",
//   //     path: ["otherExpertise"],
//   //   };
//   // }
//   // if (data?.exprtArea || data?.otherArea?.trim()?.length) {
//   //   return {
//   //     message: "This field is required .",
//   //     path: ["otherArea"],
//   //   };
//   // }
// });
interface ProgProps {
  data: any;
}
const Form: React.FC<ProgProps> = ({ data }) => {
  // const handleSchema =
  type SelectOption = { value: string; label: string };
  const { setLoading } = useAppContext();
  const navigate = useNavigate();
  const params = useParams().id;
  const [show, setShow] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [licType, setLicType] = useState<Country[]>([]);
  const [visa, setVisa] = useState<Country[]>([]);
  const [qual, setQual] = useState<Country[]>([]);
  const [expert, setExpert] = useState<Country[]>([]);
  const [experience, setExperience] = useState<Country[]>([]);
  const [area, setArea] = useState<Country[]>([]);
  const [showWhatsAppField, setShowWhatsAppField] = useState(true);
  // const [loading, setLoading] = useState(false);
  const [watchLicense, setWatchLicense] = useState<boolean>(false);
  // const [watchExpert, setWatchExpert] = useState<boolean>();
  const [qualification, setQualification] = useState<boolean>();
  // const [watchArea, setWatchArea] = useState<boolean>();
  const [file, setFile] = useState<string>();
  const handleSchema = () => {
    return schema.omit({
      country: watchLicense ? undefined : true,
      type: watchLicense ? undefined : true,
      otherQualification: qualification ? undefined : true,
      whatsapp_number: showWhatsAppField ? undefined : true,
    });
  };
  const mainSchema = handleSchema();
  const { control, register, handleSubmit, setValue, watch, formState } =
    useForm<any>({
      resolver: zodResolver(mainSchema),
    });
  console.log(watch(), formState.errors);
  // const watchLicense =
  //   watch("drivingLicense.hasLicense") == true ? true : false;
  const watchMobileNumber = watch("mobileNumber");

  const onSubmit = (data: any) => {
    console.log({ data });
    setLoading(true);
    const formData = new FormData();
    if (data) {
      if (data?.fullName) {
        formData.append("fullname", data.fullName);
      }
      if (data?.dob) {
        formData.append("date_of_birth", data.dob);
      }
      if (data?.nationality?.value) {
        formData.append("nationality", data.nationality.value);
      }
      if (data?.highestQualification?.value) {
        formData.append(
          "highest_qualification",
          data.highestQualification.value
        );
      }
      if (data?.currentLocation) {
        formData.append("current_location", data.currentLocation);
      }
      if (data?.visaStatus?.value) {
        formData.append("visa_status", data.visaStatus.value);
      }
      if (data?.hasLicense.value == "true") {
        formData.append("licence_issuing_country", data?.country?.value);
      }
      if (data?.hasLicense.value == "true") {
        formData.append("licence_type", data?.type?.value);
      }
      if (data?.mobileNumber) {
        formData.append("mobile_number", data.mobileNumber);
      }
      if (!showWhatsAppField) {
        formData.append("whatsapp_number", data.whatsapp_number);
      } else if (data?.whatsapp_number) {
        formData.append("whatsapp_number", data.mobileNumber);
      }
      if (data?.email) {
        formData.append("email", data.email);
      }
      if (data?.fieldOfSpecialization) {
        formData.append("specialization", data.fieldOfSpecialization);
      }
      if (data?.linkedin) {
        formData.append("linkedin_url", data.linkedin);
      }
      if (data?.otherQualification && qualification) {
        formData.append("other_highest_qualification", data.otherQualification);
      }
      if (data?.otherExpertise) {
        formData.append("other_industry_expertise", data.otherExpertise);
      }
      if (data?.otherArea) {
        formData.append("other_area_expertise", data.otherArea);
      }
      if (data?.experience?.value) {
        formData.append("experience", data.experience.value);
      }
      if (
        data?.certifications &&
        data?.experience?.value?.toLowerCase() !== "fresher"
      ) {
        formData.append("professional_qualification", data.certifications);
      }
      if (
        data?.currentPosition &&
        data?.experience?.value?.toLowerCase() !== "fresher"
      ) {
        formData.append("current_position", data.currentPosition);
      }
      if (
        data?.currentOrganization &&
        data?.experience?.value?.toLowerCase() !== "fresher"
      ) {
        formData.append("current_organisation", data.currentOrganization);
      }
      if (data?.resume && data.resume[0]) {
        formData.append("resume", data.resume[0]);
      }
      if (params) {
        formData.append("role_applied", params as string);
      }
      // if (data?.industryExpertise) {
      //   data.industryExpertise.forEach((item) => {
      //     if (item?.value) {
      //       formData.append("industry_expertise", item.value);
      //     }
      //   });
      // }
      // if (data?.areasOfExpertise) {
      //   data.areasOfExpertise.forEach((item) => {
      //     if (item?.value) {
      //       formData.append("area_expertise", item.value);
      //     }
      //   });
      // }
    }

    applicantRegister(formData);
  };
  const applicantRegister = async (data) => {
    try {
      const response = await axiosInstance.post(
        `https://api.stratsourceme.com/api/v1/careers/applicants`,
        data
      );
      setLoading(false);
      Swal.fire({
        title: "Success",
        text: "Application submitted successfully",
        icon: "success",

        confirmButtonColor: "#ff4f00",
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    } catch (err: any) {
      console.log(err);

      Swal.fire({
        title: "Error",
        text: `${genErrorMessage(err)}`,
        icon: "error",

        confirmButtonColor: "#ff4f00",
        confirmButtonText: "Okay",
      });
      // .then((result) => {
      //   // if (result.isConfirmed) {
      //   //   navigate("/");
      //   // }
      // })
    } finally {
      setLoading(false);
    }
  };
  const fetchCountryData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/countries`
      );
      // console.log(response)
      const arr =
        response?.data?.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      // console.log(response)
      setCountries(arr);
    } catch (err: any) {}
  };
  const genErrorMessage = (err: any) => {
    if (err) {
      const errObj = err?.response?.data;
      const message: string[] = [];
      if (typeof errObj == "object") {
        Object.keys(errObj).forEach((item) => {
          message.push(String(errObj[item] as string));
        });
        return String(message);
      } else {
        return "Something Went Wrong";
      }
    } else {
      return "Something Went Wrong";
    }
  };
  const loadOptions = (
    inputValue: string,
    callback: (options: SelectOption[]) => void
  ) => {
    fetchLocationData(inputValue)
      .then((data) => {
        const options = data.map((item: Country) => ({
          value: item.id, // assuming `id` is the unique identifier
          label: item.name, // the label shown to the user
        }));
        callback(options); // Provide options to callback
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  };

  const fetchLocationData = async (inputValue: string): Promise<Country[]> => {
    const response = await axiosInstance.get(
      `https://api.stratsourceme.com/api/v1/registers/locations?search=${inputValue}`
    );
    return response.data; // Return data as Country array
  };

  const fetchlicenceData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/licence_types`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setLicType(arr);
    } catch (err: any) {}
  };
  const fetchVisaData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/visa_statuses`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setVisa(arr);
    } catch (err: any) {}
  };
  const fetchQualData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/qualification_types`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setQual(arr);
    } catch (err: any) {}
  };
  const fetchExperienceData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/experiances`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setExperience(arr);
    } catch (err: any) {}
  };
  const fetchExpertiseData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/industry_expertises`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setExpert(arr);
    } catch (err: any) {}
  };
  const fetchAreaData = async () => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/registers/area_expertises`
      );
      // console.log(response)
      const arr =
        response?.data.map((item) => ({
          value: item.id, // Use the country name as the value
          label: item.name, // Use the country name as the label
        })) || [];
      setArea(arr);
    } catch (err: any) {}
  };

  useEffect(() => {
    const qualifications = watch("highestQualification"); // Store watched value in a variable

    if (qualifications && qualifications.label === "Others") {
      console.log(1);
      setQualification(true);
      // setValue("qualOther", true);
    } else {
      console.log(2);
      setQualification(false);
      // setValue("qualOther", false); // Fix value to false when condition isn't met
    }
  }, [watch("highestQualification")]);

  useEffect(() => {
    fetchVisaData();
    fetchCountryData();
    fetchlicenceData();
    fetchQualData();
    fetchExperienceData();
    fetchExpertiseData();
    fetchAreaData();
  }, []);
  useEffect(() => {
    setWatchLicense(watch().hasLicense?.value == "true" ? true : false);
    // console.log(watch("drivingLicense.hasLicense.value"));
  }, [watch().hasLicense?.value]);
  // useEffect(() => {
  //   // setWatchLicense(watch().hasLicense?.value == "true" ? true : false);
  //   // console.log(watch("drivingLicense.hasLicense.value"));
  // }, [watch().hasLicense?.value]);
  useEffect(() => {
    setFile(watch("resume[0].name"));
    // console.log(watch("resume[0].name"));
  }, [watch("resume")]);
  // console.log(watch());
  // console.log(formState.errors);
  const getSelectStyles = (theme: any) => {
    return {
      ...theme,
      // borderRadius: 0,
      colors: {
        ...theme.colors,
        primary25: "#ff510085",
        primary: "#ff4f00",
      },
    };
  };

  return (
    <>
      <div className="max-w-2xl mx-auto placeholder-gray-200 text-gray-900">
        <div
          className="w-full max-w-full bg-white rounded-xl shadow-lg mb-2 carCard"
          data-aos="fade-in"
        >
          {/* Accordion Header */}
          <div
            className="flex justify-between items-center p-3 cursor-pointer h-full"
            // onClick={toggleAccordion}
          >
            <div className="flex flex-col justify-between w-full h-full">
              <div className="flex items-center justify-between w-full ">
                {/* <div className="text-xl capitalize font-medium ">{data?.name}</div> */}
                <div className="font-medium text-primary text-[14px]">
                  <span className="text-gray-300">Last Date: </span>
                  {data?.last_date &&
                    moment(data?.last_date).format("DD-MM-YY, hh:mm")}
                </div>

                {/* <div className="relative flex items-center justify-end ">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNav(data?.id);
                          }}
                          className="relative flex h-6 items-center justify-center px-3 text-sm font-semibold text-white bg-primary rounded-full hover:scale-105 transition-all"
                        >
                          Apply
                        </button>
                      </div> */}
              </div>

              <div className=" venturi text-start text-[14px] mt-3">
                {data?.description
                  ? parse(data?.description)
                  : "No data to display"}
                {/* Our mission is to empower organizations through exceptional
                  procurement consultancy services. We focus on optimizing
                  supply chains, reducing costs, and enabling our clients to
                  achieve their strategic objectives. By delivering innovative
                  sourcing solutions tailored to individual needs, we position
                  ourselves as trusted partners in our clients' pursuit of
                  operational excellence and sustainable growth.
                  Our mission is to empower organizations through exceptional
                  procurement consultancy services. We focus on optimizing
                  supply chains, reducing costs, and enabling our clients to
                  achieve their strategic objectives. By delivering innovative
                  sourcing solutions tailored to individual needs, we position
                  ourselves as trusted partners in our clients' pursuit of
                  operational excellence and sustainable growth.
                  Our mission is to empower organizations through exceptional
                  procurement consultancy services. We focus on optimizing
                  supply chains, reducing costs, and enabling our clients to
                  achieve their strategic objectives. By delivering innovative
                  sourcing solutions tailored to individual needs, we position
                  ourselves as trusted partners in our clients' pursuit of
                  operational excellence and sustainable growth.
                  Our mission is to empower organizations through exceptional
                  procurement consultancy services. We focus on optimizing
                  supply chains, reducing costs, and enabling our clients to
                  achieve their strategic objectives. By delivering innovative
                  sourcing solutions tailored to individual needs, we position
                  ourselves as trusted partners in our clients' pursuit of
                  operational excellence and sustainable growth. */}
              </div>

              <div className="flex justify-between items-end mt-3">
                {/* <div className="flex items-center mt-1">
                        <div className="mb-1 text-primary text-[14px]">Tags&nbsp;: </div>
                        <div className="flex py-3 mx-3 h-6 w-fit items-center justify-center px-2 text-gray-700 text-[10px] border border-gray-700 rounded-3xl shadow-lg capitalize">
                          career
                        </div>
                      </div> */}
                {data?.eligibility && (
                  <div className="flex items-start ">
                    <div className=" text-primary text-[14px]">
                      Eligibility&nbsp;:{" "}
                    </div>
                    <div className=" venturi text-start text-[14px] ml-2">
                      {data?.eligibility
                        ? data?.eligibility
                        : "No eligibility to display"}
                      {/* Our mission is to empower organizations through
                        exceptional procurement consultancy services. We focus
                        on optimizing supply chains, reducing costs, */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Accordion Content */}
          {/* {isOpen && (
                  <div className="p-3 my-3 ">
                    <div className=" venturi text-start text-[13px] ml-3">
                      {data?.description
                        ? parse(data?.description)
                        : "No data to display"}
                    </div>
                    <div className="ml-3  text-lg pt-3 flex justify-center">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNav(data?.id);
                        }}
                        className="relative flex h-6 items-center justify-center px-3 text-sm font-semibold text-white bg-primary rounded-full hover:scale-105 transition-all"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                )} */}
        </div>
        {data?.external_link && !data?.expired && (
          <div className="my-5  text-lg w-full flex justify-center ">
            {/* {isOpen ? <FaChevronDown /> : <FaChevronRight />} */}
            <button
              onClick={(e) => {
                {
                  window.open(data?.external_link, "_blank");
                }
              }}
              className=" flex h-7 px-5 items-center justify-center  text-sm font-semibold text-white bg-primary rounded-full hover:scale-105 transition-all"
            >
              Apply
            </button>
          </div>
        )}
      </div>

      <SpacerMini />
      {!data?.external_link && data.expired !== true && (
        <div className="max-w-2xl mx-auto placeholder-gray-200 text-gray-900 dark:text-gray-100">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // handleSubmit(onSubmit);
            }}
            className="space-y-4"
          >
            <div className=" p-4 border border-gray-200 rounded-3xl my-3">
              <h2 className="text-xl font-bold text-center">
                Applicant Information
              </h2>
              <div className="mt-3">
                <label className="flex items-start">
                  Full Name
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <input
                  {...register("fullName")}
                  placeholder="Full Name"
                  className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  type="text"
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.fullName?.message as string}
                </p>
              </div>
              <div className="mt-3">
                <label className="flex items-start">
                  Date of Birth{" "}
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <input
                  {...register("dob")}
                  type="date"
                  placeholder="dd/mm/yyyy"
                  className="w-full placeholder-gray-200 p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.dob?.message as string}
                </p>
              </div>

              <div className="mt-3">
                <label className="flex items-start">
                  Nationality
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <Controller
                  name="nationality"
                  control={control}
                  render={({ field }) => (
                    <Select
                      theme={getSelectStyles}
                      // styles={{
                      //   control: (baseStyles, state) => ({
                      //     ...baseStyles,
                      //     borderColor: state.isFocused ? 'grey' : 'red',
                      //   }),
                      // }}

                      className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                      {...field}
                      options={countries}
                      isClearable
                      isSearchable
                      placeholder="Search and Select"
                    />
                  )}
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.nationality?.message as string}
                </p>
              </div>

              {/* <div className="mt-3">
                <label className="flex items-start">Current Location (City, Country)</label>
                <Controller
                  name="currentLocation"
                  control={control}
                  render={({ field }) => (
                    <AsyncSelect
                      theme={getSelectStyles}
                      {...field}
                      cacheOptions
                      // value={}
                      loadOptions={loadOptions} // Correctly typed loadOptions
                      defaultOptions
                      className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                      // onChange={(selectedOption) =>
                      //   field.onChange(selectedOption?.value)
                      // } // Handle onChange
                      placeholder="Select"
                      isClearable
                      isSearchable
                    />
                    // <Select theme={getSelectStyles}
                    //   {...field}
                    //   className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "

                    //   options={[
                    //     { value: "New York, USA", label: "New York, USA" },
                    //     { value: "Toronto, Canada", label: "Toronto, Canada" },
                    //     // Add more options
                    //   ]}
                    //   isSearchable
                    //   placeholder="Search and Select"
                    // />
                  )}
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.currentLocation?.message as string}
                </p>
              </div> */}
              <div className="mt-3">
                <label className="flex items-start">
                  Current Location (City, Country){" "}
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <input
                  {...register("currentLocation")}
                  placeholder="City, Country"
                  className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  type="text"
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.currentLocation?.message as string}
                </p>
              </div>
              <div className="mt-3">
                <label className="flex items-start">
                  Do you have a valid Driving Licence{" "}
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <Controller
                  name="hasLicense"
                  control={control}
                  render={({ field }) => (
                    <Select
                      theme={getSelectStyles}
                      className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                      {...field}
                      options={[
                        {
                          value: "true", // Use the country name as the value
                          label: "Yes",
                        },
                        {
                          value: "false", // Use the country name as the value
                          label: "No",
                        },
                      ]}
                      placeholder="Select"
                      isClearable
                    />
                  )}
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.hasLicense?.message as string}
                </p>

                {watchLicense && (
                  <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mt-3">
                    <div className="">
                      <label className="flex items-start">
                        Type of Driving Licence{" "}
                        <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                          *
                        </span>
                      </label>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            theme={getSelectStyles}
                            className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                            {...field}
                            options={licType}
                            placeholder="Select"
                            isClearable
                          />
                        )}
                      />
                      <p className="text-[12px] text-red-600 pt-1">
                        {formState?.errors?.type?.message as string}
                      </p>
                    </div>
                    <div className="">
                      <label className="flex items-start">
                        License Issuing Country{" "}
                        <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                          *
                        </span>
                      </label>
                      <Controller
                        name="country"
                        control={control}
                        render={({ field }) => (
                          <Select
                            theme={getSelectStyles}
                            className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                            {...field}
                            options={countries}
                            isSearchable
                            placeholder="Search and Select"
                            isClearable
                          />
                        )}
                      />
                      <p className="text-[12px] text-red-600 pt-1">
                        {(formState?.errors?.country?.message as string) ?? ""}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-3">
                <label className="flex items-start">
                  Visa Status
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <Controller
                  name="visaStatus"
                  control={control}
                  render={({ field }) => (
                    <Select
                      theme={getSelectStyles}
                      className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                      {...field}
                      options={visa}
                      placeholder="Select"
                      isClearable
                    />
                  )}
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {(formState?.errors?.visaStatus?.message as string) ?? ""}
                </p>
              </div>
            </div>
            <br />
            {/* ///////////////////////////////////////////////// */}
            <div className=" p-4 border border-gray-200 rounded-3xl my-3">
              <h2 className="text-xl font-bold text-center">Contact Details</h2>
              {/* <div className="mt-3">
                <label className="flex items-start">Mobile Number</label>
                <input
                  {...register("mobileNumber")}
                  className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  type="text"
                  placeholder="Mobile Number"
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.mobileNumber?.message as string}
                </p>
              </div> */}
              <div className="mt-3">
                <label className="flex items-start">
                  Mobile Number{" "}
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <Controller
                  name="mobileNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      international
                      id="cont-main"
                      defaultCountry="AE"
                      className="w-full contact-country p-2 mt-2 focus:border-none border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder-black bg-white text-gray-900"
                      value={watch()?.mobileNumber}
                      style={{ outline: "none" }}
                      onChange={(v: any) => setValue("mobileNumber", v)}
                    />
                  )}
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.mobileNumber?.message as string}
                </p>
              </div>
              <div className="my-4 flex items-center">
                <input
                  type="checkbox"
                  // {...register("isWhatsApp")}
                  required
                  onChange={(e) => setShowWhatsAppField(!e.target.checked)}
                  className="mr-2 w-5 h-5 "
                />
                <label className="Contact dark:text-white">
                  Is the above number a WhatsApp number?
                </label>
              </div>
              {showWhatsAppField && (
                <div className="mt-3">
                  <label className="flex items-start">
                    WhatsApp Number{" "}
                    <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                      *
                    </span>
                  </label>
                  {/* <input
                  {...register("whatsappNumber")}
                  className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  type="text"
                  placeholder="Whatsapp Number"
                /> */}

                  <Controller
                    name="whatsapp_number"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        international
                        id="cont-main"
                        defaultCountry="AE"
                        className="w-full contact-country text-gray-900 bg-white p-2 mt-2 focus:border-none border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder-black"
                        value={watch()?.whatsapp_number ?? ""}
                        style={{ outline: "none" }}
                        onChange={(v: any) =>
                          setValue("whatsapp_number", v ? v : "")
                        }
                      />
                    )}
                  />
                  <p className="text-[12px] text-red-600 pt-1">
                    {formState.errors.whatsapp_number?.message as string}
                  </p>
                </div>
              )}

              <div className="mt-3">
                <label className="flex items-start">
                  Email Address{" "}
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <input
                  {...register("email")}
                  className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  type="email"
                  placeholder="Email Address"
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.email?.message as string}
                </p>
              </div>

              <div className="mt-3">
                <label className="flex items-start">
                  LinkedIn Profile URL (https://url.com){" "}
                  {/* <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span> */}
                </label>
                <input
                  {...register("linkedin")}
                  className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  type="text"
                  placeholder="Profile Url"
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.linkedin?.message as string}
                </p>
              </div>
            </div>
            <br />
            {/* /////////////////////////////////////////////// */}
            <div className=" p-4 border border-gray-200 rounded-3xl my-3">
              <h2 className="text-xl font-bold text-center">
                Professional Profile
              </h2>

              {/* <div className="mt-3">
            <label className="flex items-start">Highest Qualification</label>
            <Select theme={getSelectStyles}
              {...register("highestQualification")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
            >
              <option value="">Select Qualification</option>
              <option value="Bachelor’s">Bachelor’s</option>
              <option value="Master’s">Master’s</option>
              <option value="PhD">PhD</option>
            </select>
            <p className="text-[12px] text-red-600 pt-1">{formState.errors.highestQualification?.message as string}</p>
          </div> */}
              <div className="mt-3">
                <label className="flex items-start">
                  Highest Qualification{" "}
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <Controller
                  name="highestQualification"
                  control={control}
                  render={({ field }) => (
                    <Select
                      theme={getSelectStyles}
                      className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                      {...field}
                      options={qual}
                      isClearable
                      placeholder="Search and Select"
                    />
                  )}
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {(formState?.errors?.highestQualification
                    ?.message as string) ?? ""}
                </p>
              </div>
              {/* <div className="mt-5">
            <div className="flex items-center space-x-2">
              <input
                {...register("qualOther")}
                type="checkbox"
                className="w-5 h-5 border rounded-md text-primary focus:ring-2 focus:ring-primary focus:outline-none dark:text-gray-900"
              />
              <span className="">Could not find your qualification?</span>
            </div> */}
              {/* <p className="text-[12px] text-red-600 pt-1">{formState.errors.fieldOfSpecialization?.message as string}</p> */}
              {/* </div> */}

              {qualification && (
                <div className="mt-3">
                  <label className="flex items-start">
                    Please specify your qualification{" "}
                    <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                      *
                    </span>
                  </label>
                  <input
                    {...register("otherQualification")}
                    className="w-full p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                    type="text"
                    placeholder="Specify Qualification"
                  />
                  <p className="text-red-600 pt-1-500">
                    {formState.errors.otherQualification?.message as string}
                  </p>
                </div>
              )}
              <div className="mt-3">
                <label className="flex items-start">
                  Specialization{" "}
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <input
                  {...register("fieldOfSpecialization")}
                  className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  type="text"
                  placeholder="Specialization"
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.fieldOfSpecialization?.message as string}
                </p>
              </div>

              {/* <div className="mt-3">
            <label className="flex items-start">Years of Relevant Experience</label>
            <Select theme={getSelectStyles}
              {...register("experience")}
              className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
            >
              <option value="">Select Years of Experience</option>
              {[...Array(16).keys()].map((i) => (
                <option key={i} value={i + 1}>
                  {i + 1} {i === 15 && "+"}
                </option>
              ))}
            </select>
            <p className="text-[12px] text-red-600 pt-1">{formState.errors.experience?.message as string}</p>
          </div> */}
              <div className="mt-3">
                <label className="flex items-start">
                  Years of Relevant Experience{" "}
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                <Controller
                  name="experience"
                  control={control}
                  render={({ field }) => (
                    <Select
                      theme={getSelectStyles}
                      className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                      {...field}
                      options={experience}
                      isClearable
                      placeholder="Search and Select"
                    />
                  )}
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {(formState?.errors?.experience?.message as string) ?? ""}
                </p>
              </div>
              {watch("experience")?.label?.toLowerCase() !== "fresher" && (
                <>
                  <div className="mt-3">
                    <label className="flex items-start">Certifications</label>
                    <input
                      {...register("certifications")}
                      className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                      type="text"
                      placeholder="Seperated by comma (eg: CIPS,CSCP)"
                      // placeholder="CIPS, CSCP etc"
                    />
                  </div>

                  <div className="mt-3">
                    <label className="flex items-start">
                      Current Position (Job Title)
                    </label>
                    <input
                      {...register("currentPosition")}
                      className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                      type="text"
                      placeholder="Current Position"
                    />
                    <p className="text-[12px] text-red-600 pt-1">
                      {formState.errors.currentPosition?.message as string}
                    </p>
                  </div>

                  <div className="mt-3">
                    <label className="flex items-start">
                      Current Organization (Name of the company)
                    </label>
                    <input
                      {...register("currentOrganization")}
                      className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                      type="text"
                      placeholder="Current Organization"
                    />
                    <p className="text-[12px] text-red-600 pt-1">
                      {formState.errors.currentOrganization?.message as string}
                    </p>
                  </div>
                </>
              )}

              {/* <div className="mt-3">
            <label className="flex items-start">Industry Expertise</label>
            <Controller
              name="industryExpertise"
              control={control}
              render={({ field }) => (
                <Select theme={getSelectStyles}
                  {...field}
                  options={[
                    { value: "Manufacturing", label: "Manufacturing" },
                    { value: "Retail", label: "Retail" },
                    { value: "Real Estate", label: "Real Estate" },
                    { value: "Healthcare", label: "Healthcare" },
                  ]}
                  isMulti
                  placeholder="Select Industry Expertise"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">{formState.errors.industryExpertise?.message as string}</p>
          </div> */}
              {/* <div className="mt-3">
            <label className="">Industry Expertise</label>
            <Controller
              name="industryExpertise"
              control={control}
              render={({ field }) => (
                <Select
                  theme={getSelectStyles}
                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  {...field}
                  options={expert}
                  isClearable
                  isSearchable
                  isMulti
                  placeholder="Multiple Select"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">
              {(formState?.errors?.experience?.message as string) ?? ""}
            </p>
          </div> */}
              {/* {watchExpert && ( */}
              <div className="mt-3">
                <div className="flex items-center">
                  <label
                    className="cursor-pointer"
                    htmlFor="industry-expertise"
                  >
                    Industry Expertise
                  </label>
                  <span
                    className="ml-2 text-gray-100 hover:text-gray-700 cursor-pointer border rounded-full text-[10px] px-[5px]"
                    title="Eg : Mining and Metals,
Oil and Gas,  Renewable Energy (Solar, Wind, etc.), 
Water and Wastewater Management, Automotive Manufacturing, 
Aerospace and Defense, Electronics and Semiconductors, 
Food and Beverage Processing, 
Textiles and Apparel etc. 
"
                  >
                    ?
                  </span>
                </div>
                <input
                  {...register("otherExpertise")}
                  className="w-full  p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder:text-sm md:placeholder:text-base"
                  type="text"
                  placeholder="Seperated by comma (e.g : Oil and Gas, Agriculture)"
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.otherExpertise?.message as string}
                </p>
              </div>
              {/* // )} */}
              {/* <div className="mt-3">
            <label className="">Areas of Expertise</label>
            <Controller
              name="areasOfExpertise"
              control={control}
              render={({ field }) => (
                <Select
                  theme={getSelectStyles}
                  className="w-full mt-3 border text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
                  {...field}
                  options={area}
                  isClearable
                  isSearchable
                  isMulti
                  placeholder="Multiple Select"
                />
              )}
            />
            <p className="text-[12px] text-red-600 pt-1">
              {(formState?.errors?.areasOfExpertise?.message as string) ?? ""}
            </p>
          </div> */}
              {/* {watchArea && ( */}
              <div className="mt-3">
                <div className="flex items-center">
                  <label
                    className="cursor-pointer"
                    htmlFor="industry-expertise"
                  >
                    Area of Expertise
                  </label>
                  <span
                    className="ml-2 text-gray-100 hover:text-gray-700 cursor-pointer border rounded-full text-[10px] px-[5px]"
                    title=" Eg : Strategic Planning, Process Optimization,
Supplier Negotiation,  Relationship Management, 
Procurement Management, Business Development, Sales and Marketing, 
Financial Planning, Budgeting, 
Content Strategy and Development, 
Employee Engagement and Retention, 
Regulatory Compliance and Auditing, 
Product Innovation and Development etc."
                  >
                    ?
                  </span>
                </div>
                {/* <label className="flex items-start">Area of Expertise</label> */}
                <input
                  {...register("otherArea")}
                  className="w-full p-2 mt-3 dark:text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary placeholder:text-sm md:placeholder:text-base"
                  type="text"
                  placeholder="Separated by comma (e.g: Vendor Management, Contract Negotiation)"
                />
                <p className="text-[12px] text-red-600 pt-1">
                  {formState.errors.otherArea?.message as string}
                </p>
              </div>

              {/* )} */}
            </div>
            {/* //////////////////////////// */}
            <br />
            <div className=" p-4 border border-gray-200 rounded-3xl my-5">
              <h2 className="text-xl font-bold text-center">
                Documents Upload
              </h2>
              <div className="flex justify-evenly w-full items-center mt-3">
                <label className="flex items-start">
                  Resume/CV{" "}
                  <span className="text-[12px] text-red-600 mt-[2px] ml-1">
                    *
                  </span>
                </label>
                {/* <div className="mt-3">
            
            <input
              {...register("resume")}
              className="w-full  p-2 mt-3 text-gray-900 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary "
              type="file"
              // name={file}
              accept=".pdf,.doc,.docx"
            />
            <p className="text-[12px] text-red-600 pt-1">{formState.errors.resume?.message as string}</p>
          </div> */}
                <div className=" ">
                  <Controller
                    name="resume"
                    control={control}
                    render={({ field }) => (
                      <input
                        onChange={(e) => {
                          setValue("resume", e.target.files);
                        }}
                        className="w-[100px]    text-gray-900     outline-none border-none   boxSh"
                        type="file"
                        accept=".pdf,.doc,.docx"
                      />
                    )}
                  />
                  <p className="text-[12px] text-red-600 pt-1">
                    {formState.errors.resume?.message as string}
                  </p>
                  {file && <label className="">{file}</label>}
                </div>
              </div>
            </div>

            {/* Continue other fields similarly */}

            <br />
            <div className="w-full flex justify-center mt-5">
              <div className="">
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                >
                  <span className="relative text-sm font-semibold text-white">
                    Submit
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Form;
