import React from "react";
import Container from "../../../../components/Container";
import Hexagon from "../../../../components/honeycomb/Hexagon";
import ResponsiveHoneycomb from "../../../../components/honeycomb/ResponsiveHoneycomb";
import { features } from "../data/honeycomb";

const Whatdo = () => {
  return (
    <div className="">
      <Container>
        <div className="space-y-6 justify-between text-gray-600 flex flex-col  lg:flex-row md:gap-6 md:space-y-0 lg:gap-12 lg:items-center">
          <div
            className="w-full lg:w-1/2 "
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            <h2 className="text-3xl font-bold text-gray-900 md:text-4xl dark:text-white">
              What We Do
            </h2>
            <p
              className="my-8 hover:shadow-glow group p-3 sm:p-4 rounded-3xl bg-gradient-to-r from-[#ff51007b] to-[#219b9d7a] border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10 text-gray-900 dark:text-gray-100"
              data-aos="zoom-in-up"
            >
              At Strategic Sourcing Middle East, we pride ourselves on
              delivering measurable results that drive operational excellence.
            </p>
            <p
              className="my-8 hover:shadow-glow group p-3 sm:p-4 rounded-3xl bg-gradient-to-r from-[#ff51007b] to-[#219b9d7a] border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10 text-gray-900 dark:text-gray-100"
              data-aos="zoom-in-up"
            >
              We enable businesses to overcome sourcing challenges and achieve
              their strategic objectives while unlocking substantial cost
              savings.
            </p>
          </div>
          <div
            className="w-full lg:w-1/2 "
            data-aos="fade-in"
            data-aos-duration="2000"
          >
            <ResponsiveHoneycomb
              defaultWidth={1000}
              size={75}
              items={features}
              renderItem={(item, index) => (
                <Hexagon
                  index={index}
                  className={`flex items-center justify-center text-center ${
                    index % 2 === 1
                      ? "bg-[#219b9d7a] hover:bg-[#219b9da9]"
                      : "bg-[#ff51007b] hover:bg-[##ff5100c2]"
                  } text-gray-900 dark:text-white hover:text-white p-2 hover:scale-125 hover:z-50 z-0`}
                >
                  {item.name}
                </Hexagon>
              )}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Whatdo;
