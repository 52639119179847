import React from "react";
import "./popup2.css";
import parse from "html-react-parser";
import SimpleSlider from "../slider/slider";
import ProductImageGalleryLeftThumb from "../../pages/public/updateDetail/components/newDetail";

interface PopupProps {
  setOpen: (value: boolean) => void;
  data?: any;
  active: number;
}
const Popup2: React.FC<PopupProps> = ({ setOpen, data, active }) => {
  return (
    <div className="modal z-50">
      <article className="modal-container">
        <header className="modal-container-header  text-primary">
          <h1 className="modal-container-title">{data?.name}</h1>
          <button
            className="icon-button"
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="currentColor"
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              />
            </svg>
          </button>
        </header>
        <section className="modal-container-body ">
          <div
            className=" w-full h-full z-10"
            //   data-aos="zoom-in-up"
          >
            {data ? (
              <ProductImageGalleryLeftThumb data={data} active={active} />
            ) : (
              "no gal"
            )}
          </div>
        </section>
      </article>
    </div>
  );
};

export default Popup2;
