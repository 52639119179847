import React from "react";

const Team: React.FC = () => {
  return (
    <div className=" text-center flex justify-center sectional-container">
      {/* <div className="container">
        <div className="welcome-content text-center " data-aos-duration="2000" data-aos="fade-in">
          <h5 className="font-medium text-xl text-gray-600 dark:text-gray-300">Who Are We</h5>
          <h1 className="font-medium text-3xl text-gray-900 dark:text-white p-3 ">
            Our Team
          </h1>
          <div className="w-full pt-3 flex  justify-center">
            <div className="border-b-2  border-b-black w-[100px] dark:border-b-white "></div>
          </div>
          <p className="p-10  text-gray-600 dark:text-gray-300 font-normal text-base">
            Our team of seasoned procurement professionals brings a wealth of
            experience and expertise to every project. Committed to upholding
            the highest standards of integrity and excellence, we work
            collaboratively with clients to understand their unique needs and
            provide innovative solutions that foster sustainable partnerships
            and strategic growth.
          </p>
        </div>
      </div> */}
      <div className="hidden py-10 border-y border-gray-100 dark:border-gray-800 sm:flex justify-between gap-6 ">
        <div
          className="text-center "
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <h6 className="text-lg font-semibold text-gray-700 dark:text-white ">
            Global Footprint
          </h6>
          <p className="mt-2 text-gray-500">
            Strategic Sourcing Middle East operates on a global scale, with a
            strong presence across key international markets. Our expansive
            network of vendors allows us to source high-quality suppliers and
            resources tailored to the specific needs of our clients, regardless
            of their industry or location.
          </p>
        </div>
        <div className="text-center ">
          <h6 className="text-lg font-semibold text-gray-700 dark:text-white">
            Team
          </h6>
          <p className="mt-2 text-gray-500">
            Our team of Procurement and Recruitment professionals are dedicated
            to integrity and excellence. We collaborate with clients to
            understand their unique needs, delivering innovative solutions that
            promote sustainable partnerships and strategic growth. With our
            expertise, we ensure every project is handled with the utmost
            professionalism.
          </p>
        </div>
        <div className="text-ce" data-aos="fade-left" data-aos-duration="2000">
          <h6 className="text-lg font-semibold text-gray-700 dark:text-white">
            Track Record
          </h6>
          <p className="mt-2 text-gray-500">
            We deliver measurable results by reducing procurement costs,
            enhancing supplier relationships, and streamlining processes for
            operational efficiency. With innovative strategies and a commitment
            to excellence, we empower businesses to overcome sourcing challenges
            and achieve their strategic goals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Team;
