import React, { useState } from "react";
import "./index.css";
// import Eye from "../../../../../assets/svg/eye";
import { Link, useNavigate } from "react-router-dom";
// import Popup2 from "../../../../components/popup2/popup2";
import parse from "html-react-parser";

interface UpdateCardsProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  // setClickedData: (data: any) => void; // Adjust `any` to the correct type of your data
  data: any; // Assuming `data` is a number. Change as per your use case.
}

const UpdateCards: React.FC<UpdateCardsProps> = ({
  open,
  setOpen,
  // setClickedData,
  data,
}) => {
  const navigate = useNavigate();
  const handleNav = (id: string) => {
    navigate(`/updates/${id}`);
  };
  // const [showModalOrder, setShowModalOrder] = useState(false);
  return (
    <>
      <div
        className="flex flex-col  items-start w-full max-w-full bg-gray-50 rounded-3xl cursor-pointer border border-gray-300 dark:border-gray-700 shadow-lg hover:scale-105 transition-transform duration-200"
        onClick={() => handleNav(data?.id)}
      >
        <div
          className="bg-white border-b border-b-gray-100  dark:shadow-none dark:border-b-gray-700 dark:bg-gray-800 shadow-gray-600/10 rounded-t-3xl w-full h-[336px]"
          //   data-aos="zoom-in-up"
        >
          <img
            src={data?.thumbnail ?? data?.image}
            className=" rounded-t-3xl object-cover program-card-image { h-[336px]"
          />
        </div>
        <div className="p-3 w-full">
          <div className="  text-left mx-1">
            <div className=" text-gray-800 rounded-t-3xl  text-[22px] text-left font-semibold my-2">
              <div className=" capitalize">{data?.name ?? "event name"}</div>
            </div>
            {/* <p className="my-2 text-gray-700  w-full max-w-md h-24 overflow-hidden text-ellipsis whitespace-normal leading-6"> */}
            <p className="my-1 text-gray-700 venturi w-full max-w-md h-22 min-h-[82px] line-clamp-4 text-[16px]">
              {data?.short_desc
                ? parse(data?.short_desc)
                : "No data to display"}
              {/* Our mission is to empower organizations through exceptional
                  procurement consultancy services. We focus on optimizing supply
                  chains, reducing costs, and enabling our clients to achieve their
                  strategic objectives. By delivering innovative sourcing solutions
                  tailored to individual needs, we position ourselves as trusted
                  partners in our clients' pursuit of operational excellence and
                  sustainable growth. */}
            </p>
            {/* <div className="text-primary text-[14px] my-2">
                  Venue : <span className="font-bold ">{data?.venue}</span>
                </div> */}
            {/* <p className="text-primary readMore">...Read More</p> */}
          </div>

          {/* <div className="updateEye bg-primary hover:bg-white rounded-full hover:border hover:border-primary text-white hover:text-primary">
              
    
              <Eye />
            </div> */}

          {/* <div className="w-full   flex justify-end ">
                <div className="">
                  <div
                    // to={"/programs/1"}
                    onClick={() => handleNav(data?.id)}
                    className="relative flex h-9 w-full items-center justify-center px-4 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                  >
                    <span className="relative text-sm font-semibold text-white">
                      Read More
                    </span>
                  </div>
                </div>
              </div> */}
        </div>
      </div>
    </>
  );
};

export default UpdateCards;
