import React from "react";
// import Back from "../../../../../assets/hero.jpeg";
import "./index.css";
import moment from "moment";
import Download from "../../../../../assets/svg/dwnload.svg";

interface ProgProps {
  data: any;
}
const Cards: React.FC<ProgProps> = ({ data }) => {
  return (
    <div className=" sectional-container ">
      {/* <div className="order-2 sm:order-1" data-aos="zoom-in-up">
        <div className=" relative h-full">
          <img
            src={data?.image}
            // src={Back}
            alt=""
            // height={200}
            className="programDetail-card-image "
          /> */}
      {/* <div className="p-10 bg-primary"></div> */}
      {/* </div>
      </div> */}
      <div className="order-1 sm:order-2 text-gray-600 dark:text-gray-300 font-normal text-base p-3 pt-0 flex flex-col justify-evenly">
        <h1 className="font-medium capitalize text-3xl text-gray-900 dark:text-white  text-left flex pb-3">
          {data?.name}
        </h1>
        <div className="py-3 pt-0 mt-5 flex flex-col justify-evenly gap-5">
          <div className="grid grid-cols-12">
            <span className="text-primary font-bold text-[14px] col-span-2 text-left">
              Date
            </span>
            <span className="text-primary font-bold text-[14px] col-span-1">
              :
            </span>
            <div className="col-span-9 text-left">
              <span className="font-bold text-[14px] pl-3 ">
                {" "}
                {data?.start_datetime &&
                  moment(data?.start_datetime).format("DD-MM-YY , HH:mm")}
              </span>
              <span className="text-primary font-bold text-[14px] pl-3">
                to
              </span>
              <span className="font-bold text-[14px] pl-3">
                {data?.end_datetime &&
                  moment(data?.end_datetime).format("DD-MM-YY , HH:mm")}
              </span>
            </div>
          </div>
          {data?.venue && (
            <div className="grid grid-cols-12">
              <span className="text-primary font-bold text-[14px] col-span-2 text-left">
                Venue
              </span>
              <span className="text-primary font-bold text-[14px] col-span-1">
                :
              </span>

              <span className="font-bold text-[14px] pl-3 col-span-9 text-left">
                {data?.venue}
              </span>
            </div>
          )}
          {data?.mode && (
            <div className="grid grid-cols-12">
              <span className="text-primary font-bold text-[14px] col-span-2 text-left">
                Mode
              </span>
              <span className="text-primary font-bold text-[14px] col-span-1">
                :
              </span>
              <span className="font-bold text-[14px] pl-3 col-span-9 text-left">
                {data?.mode}
              </span>
            </div>
          )}
        </div>
        {data?.brochure && (
          <div className="flex pt-3 items-center">
            <span className="font-bold text-[17px]  text-left cursor-pointer">
              Download Brochure
            </span>
            <span
              className="font-bold text-[17px] pl-2 text-left cursor-pointer"
              onClick={() => {
                window.open(data?.brochure, "_blank");
              }}
            >
              <img src={Download} className="w-6" />
            </span>
          </div>
        )}
        {/* <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
          Our mission is to empower organizations through exceptional
          procurement consultancy services. We focus on optimizing supply
          chains, reducing costs, and enabling our clients to achieve their
          strategic objectives. By delivering innovative sourcing solutions
          tailored to individual needs, we position ourselves as trusted
          partners in our clients' pursuit of operational excellence and
          sustainable growth.
        </p>
        <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
          Our mission is to empower organizations through exceptional
          procurement consultancy services. We focus on optimizing supply
          chains, reducing costs, and enabling our clients to achieve their
          strategic objectives. By delivering innovative sourcing solutions
          tailored to individual needs, we position ourselves as trusted
          partners in our clients' pursuit of operational excellence and
          sustainable growth.
        </p>
        <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
          Our mission is to empower organizations through exceptional
          procurement consultancy services. We focus on optimizing supply
          chains, reducing costs, and enabling our clients to achieve their
          strategic objectives. By delivering innovative sourcing solutions
          tailored to individual needs, we position ourselves as trusted
          partners in our clients' pursuit of operational excellence and
          sustainable growth.
        </p>
        <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
          Our mission is to empower organizations through exceptional
          procurement consultancy services. We focus on optimizing supply
          chains, reducing costs, and enabling our clients to achieve their
          strategic objectives. By delivering innovative sourcing solutions
          tailored to individual needs, we position ourselves as trusted
          partners in our clients' pursuit of operational excellence and
          sustainable growth.
        </p>
        <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
          Our mission is to empower organizations through exceptional
          procurement consultancy services. We focus on optimizing supply
          chains, reducing costs, and enabling our clients to achieve their
          strategic objectives. By delivering innovative sourcing solutions
          tailored to individual needs, we position ourselves as trusted
          partners in our clients' pursuit of operational excellence and
          sustainable growth.
        </p> */}
      </div>
    </div>
  );
};

export default Cards;
