import React, { useEffect, useState } from "react";
import About from "./components/About";
import Cards from "./components/Cards";
import Container from "../../../components/Container";
import Whatdo from "./components/Whatdo";
import Team from "./components/Team";
import Spacer from "../../../components/Spacer";
import { useNavigate, useParams } from "react-router-dom";
import DetailData from "./components/detail";
import SpacerMini from "../../../components/SpacerMini";
import axiosInstance from "../../../../utils/axios";
import { FaChevronLeft } from "react-icons/fa";
import ProductImageGalleryLeftThumb from "./components/newDetail";
// import ProductImageGalleryLeftThumb from "./components/newDetail";
// import clsx from "clsx";

const UpdateDetails: React.FC = () => {
  const param = useParams().id;
  const navigate = useNavigate();
  const [data, setData] = useState<any>({});
  const fetchProgrameData = async (id: any) => {
    try {
      const response = await axiosInstance.get(
        `https://api.stratsourceme.com/api/v1/insights/${id}`
      );
      setData({
        ...response.data,
        gallery: [
          { id: 999, image: response?.data?.image },
          ...response.data.gallery,
        ],
      });
      console.log(response.data);
    } catch (err: any) {}
  };

  useEffect(() => {
    fetchProgrameData(param);
  }, []);

  return (
    <>
      <div className="relative" id="home">
        <div
          aria-hidden="true"
          className="absolute  grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
        >
          <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
          <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
        </div>{" "}
      </div>
      <div className="flex items-center justify-center flex-col">
        <div className="py-32 w-full">
          <Container>
            <div
              className="flex items-center text-primary cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <FaChevronLeft />
              <span className="pl-2 pb-[1px]">Back</span>{" "}
            </div>
            <About data={data} />

            <SpacerMini />
            {/* <Cards data={data} /> */}
            {data?.gallery && (
              <ProductImageGalleryLeftThumb data={data?.gallery} thumb />
            )}
            <Spacer />

            <DetailData data={data} />
            <Spacer />
            {/* <div className={clsx("shop-area")}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                

                    <ProductImageGalleryLeftThumb />
                  </div>
                  <div className="col-lg-6 col-md-6">
                  </div>
                </div>
              </div>
            </div> */}
          </Container>
        </div>
      </div>
    </>
  );
};
export default UpdateDetails;
